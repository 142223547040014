import React, { useState, useEffect } from 'react';
import { auth, firestore} from './firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

import './App.css';
import { LogoSVG2} from './Logosvg';

function Login() {
    const {jobId} = useParams();
    // const user = auth.currentUser;
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [jobName, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const docRef = doc(firestore, "jobs", jobId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setJob(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching user data:", error)
            }
            setLoading(false)      
        };
        fetchJob();
      }, [jobId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsSubmitting(true); // Start submitting
        
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        const {email, password} = formData
        try {
          await signInWithEmailAndPassword(auth, email, password);
          
          navigate(`/${jobId}/entry-form`);  // Navigate to entry form on success
        } catch (error) {
          alert(error.message);
          setIsSubmitting(false);
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                navigate(`/${jobId}/entry-form`);
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    if (loading) {
        return <p>Loading...</p>
    }
    if (!jobName){
        return <p>No Job Found.</p>
    }

    return (
        <div className='Appv2'>
            <LogoSVG2/>
            <h5 className='textv2' style={{margin:'10px', marginBottom:'50px'}}>Login to continue</h5>
            
            <div className='form-container'>
                <form onSubmit={handleLogin}>
                    <label htmlFor='email'><span className='form-text'>Email</span>
                        <input id='email' type="text" name="email" placeholder="ex: bob@example.com" onChange={handleInputChange} className="input-field" required />
                    </label>
                    <label htmlFor='password'><span className='form-text'>Password</span>
                        <input id='password' type="password" name="password" placeholder="***********" onChange={handleInputChange} className="input-field" required />
                    </label>
                    <button type="submit" className="submit-button">
                        {isSubmitting ? 'Loggin In...' : 'Login' }
                    </button>
                    <p className='form-text' style={{textAlign:'center', marginTop:'20px'}}>Your Password has been shared over your email</p>
                    <p className='form-text' style={{textAlign:'center'}}>Facing Issues? Email us: contact@anantgpt.com</p>
                </form>
            </div>
        </div>
    );
}

export default Login;
