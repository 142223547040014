import React, { useState, useEffect }from 'react';
import './App.css';
import { ReactComponent as Logo2 } from './logo2.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Lottie from 'react-lottie';
import animationData from './animation.json';


function LogoSVG2() {
  return <div>
    <Logo2 style={{width: '100px', height: '100px'}}/>
  </div>;
};

function LottieAnimation() {
  const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice' // Supports the aspect ratio of the animation
      }
  };

  return (
      <div>
          <Lottie options={defaultOptions} height={100} width={100} />
      </div>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

function ProgressBarv2({ progress }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
      {progress < 100 ? (
        <span>
        <CircularProgressWithLabel value={progress} />
          <p style={{fontSize:'8px', marginTop:'0'}}>Uploading</p>
        </span>
      ) : (
        <span style={{color:'green'}}>
          <CheckCircleOutlineIcon style={{marginBottom:'0'}}/>
          <p style={{fontSize:'8px', marginTop:'0'}}>Uploaded</p>
        </span>
        // <p style={{fontSize:'8px'}}>Uploaded</p>
      )}
    </div>
  );
};

function FluentMic32Regular(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} viewBox="0 0 32 32" {...props}><path fill="black" d="M16 2a6 6 0 0 0-6 6v8a6 6 0 0 0 12 0V8a6 6 0 0 0-6-6m4 14a4 4 0 0 1-8 0V8a4 4 0 0 1 8 0zM7 15a1 1 0 0 1 1 1a8 8 0 1 0 16 0a1 1 0 1 1 2 0c0 5.186-3.947 9.45-9.001 9.95L17 26v3a1 1 0 1 1-2 0v-3l.001-.05C9.947 25.45 6 21.187 6 16a1 1 0 0 1 1-1"></path></svg>);
};

export {LogoSVG2, 
  CircularProgressWithLabel, ProgressBarv2, 
  LottieAnimation}; 