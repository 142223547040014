import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import RecordRTC from 'recordrtc';
import { useParams } from 'react-router-dom';
import './chatbot.css';
import './App.css';
import {LottieAnimation, LogoSVG2, ProgressBarv2} from './Logosvg';
import ReactMarkdown from 'react-markdown';
import { auth} from './firebase-config';
import Recorder from './recorderv2';
import BlipSound from './blip.mp3'
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';

function ChatBot() {
    const user = auth.currentUser;
    const {jobId} = useParams();
    const userId = user.uid;
    const userEmail = user.email;
    const [recorder, setRecorder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isThinking, setIsThinking] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [response, setResponse] = useState('');
    const [isFirstQuestion, setIsFirstQuestion] = useState(false);
    const [isQuestionAsked, setIsQuestionAsked] = useState(false);
    const [displayedResponse, setDisplayedResponse] = useState('');
    const [isRecordingStart, setRecordingStart] = useState(false);
    const [cameraEnabledv2,setCameraEnabledv2] = useState(false);
    const [micEnabledv2, setMicEnabledv2] = useState(false);
    const [screenEnabledv2, setScreenEnabledv2] = useState(false);
    const [iscamRecordingv2, setIscamRecordingv2] = useState(false);
    const [cameraButton, setCameraButton] = useState(false);
    const [screenButton, setScreenButton] = useState(false);
    const [recordButton, setrecordButton] = useState(false);
    const [interviewEnded, setInterviewEnded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [displayedText, setDisplayedText] = useState('');
    const audioRef = useRef(new Audio(BlipSound));

    const enableCamerav2 = () => {
        // Assuming camera and mic are from the same stream if both are used together
        setCameraEnabledv2(true);
        setMicEnabledv2(true);  // You might separate these if needed
        setCameraButton(true);
    };

    const enableScreenv2 = () => {
        setScreenEnabledv2(true);
        setScreenButton(true);
    };

    const enableRecordingv2 = () => {
        setIscamRecordingv2(true);
        setrecordButton(true);
        setIsFirstQuestion(true);
    };

    const startRecording = async () => {
        if (isRecordingStart) {
            // try { 
                // playSound();
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const newRecorder = new RecordRTC(stream, { 
                    type: 'audio', 
                    mimeType: 'audio/webm;codecs=opus', 
                    audioBitsPerSecond: 64000 // Set bitrate to 64kbps // 
                    });
                newRecorder.startRecording();
                setRecorder(newRecorder);
                setIsRecording(true);
                setIsQuestionAsked(false);
            // }

        } else {
            alert("Recording not started yet. Please start the recording.")
        }
    };

    const stopRecording = () => {
        recorder.stopRecording(() => {
            const blob = recorder.getBlob();
            sendAudio(blob);
            setIsRecording(false);
            setIsProcessing(true);
            setIsQuestionAsked(false);
        });
    };

    const sendAudio = (blob) => {
        const formData = new FormData();
        formData.append("audio", blob);
        const axiosRequest = axios.post('https://antgpt-420615.uc.r.appspot.com/transcribe', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: 180000 // 180 seconds
        });

        Promise.race([
            axiosRequest,
            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 180000))
        ])
        .then(response => {
            setTranscript(response.data.transcript);
            askChatGPT(response.data.transcript);
            setIsProcessing(false);
            setIsThinking(true);
        })
        .catch(error => {
            if (error.code === 'ECONNABORTED') {
                setTranscript("It was taking too long to transcribe. Please ask next question.");
                askChatGPT("It was taking too long to transcribe. Please ask next question.");
                setIsProcessing(false);
                setIsThinking(true);
            } else {
                console.error('Error:', error);
            }
        });
    };

    const askChatGPT = (text) => {
        setIsRecording(false);
        axios.post('https://antgpt-420615.uc.r.appspot.com/respond', { text, userId, jobId, userEmail})
            .then(response => { 
              setResponse(response.data.response);
              setIsThinking(false);
            })
            .catch(error => { 
              console.error('Error:', error);
              setIsThinking(false);
              simulateTyping(response.data.response);
            });
    };

    const simulateTyping = (markdownText) => {
        
            let index = 0;
            // setIsTyping(true);
            const interval = setInterval(() => {
                setDisplayedText(markdownText.slice(0,index));

                index++;
                
                if (index > markdownText.length) {
                    clearInterval(interval);
                    // setIsTyping(false);
                }
            }, 10); // Adjust typing speed here
    
            return () => clearInterval(interval);
    };

    useEffect(() => {
        if(response==='--Your Interview is completed. Our HR team will reach out to you with the results. Click above to stop the recording and wait for your test to upload succesfully.'){
            setIsQuestionAsked(false);
            simulateTyping("--Your Interview is completed. Our HR team will reach out to you with results. Please wait couple of minutes for the interview to upload successfully.");
            setIsQuestionAsked(true);
            setInterviewEnded(true);
        }
        else {
            setIsQuestionAsked(false);
            simulateTyping(response);
            setIsQuestionAsked(true);
        }
        
    }, [response]);


    return (
        <div className="chatbot">
            
            {/* <div className='dashboard'> */}
            <div className='interviewv2'>
            <LogoSVG2/>
            <div className='Top-half'>
                <div>
                {/* <ReactMarkdown style={{marginTop:'0'}}>**Interviewer**</ReactMarkdown> */}
                </div>
                <div style={{marginLeft:'30px', marginRight:'30px', height:'21vh', marginTop:'0vh',fontSize:'auto'}}>
                    <ReactMarkdown>
                    {`${response}`==='' ? 
                            cameraButton ? 
                                screenButton ? 
                                    recordButton ? 
                                        interviewEnded ? '' 
                                            :'**Interviewer**\n\nUnmute the mic shown below and say **"Hey I am ready for my Interview"**, then mute again and wait for the question.'
                                        : "**Interviewer**\n\nStep 3/3: Click on the button below to Start the interview it'll start the camera, mic, audio and screen recording as well."
                                    : "**Interviewer**\n\nStep 2/3: Click on the button below and share your entire screen."
                                : "**Interviewer**\n\nTo start the interview you need to complete 3 steps first.\n\n Step 1/3: Click on the button below to turn on your camera and mic. Allow access to both camera and mic to always."
                            : `**Interviewer**\n\n${displayedText}`
                        
                    }
                    </ReactMarkdown>
                    
                    { !interviewEnded ?
                        cameraButton ? 
                            screenButton ?
                                recordButton ? '' 
                                    : <button className='submit-button' style={{width:'300px'}} onClick={enableRecordingv2}>
                                        Step 3/3: Start Interview
                                    </button> 
                                :<button className='submit-button' style={{width:'300px'}} onClick={enableScreenv2}>Step 2/3: Share your Screen</button> 
                            : <button className='submit-button' style={{width:'300px'}} onClick={enableCamerav2}>Step 1/3: Turn on your Camera and Mic</button>
                        : ''
                    }
                
                </div>
                {isThinking && <LottieAnimation/>}
                {isProcessing && <LottieAnimation/>}
                {isUploading && <ProgressBarv2 progress={uploadProgress} />}
            </div>
            {/* <div>
                {isProcessing && <div className="circle_ripple_1"></div>}
                {isThinking && <div className="circle_ripple_2"></div>}
            </div> */}
                <div className='Bottom-half'>
                    {/* <div> */}
                    
                    {/* </div> */}
                {isRecordingStart && !interviewEnded &&
                    
                    
                        <span className='Bottom-half'style={{marginTop:'0'}}>
                        <h6 style={{color:'black', fontSize:'16px'}} className={`${isRecording}`}>{isRecording ? 
                            <span className='fadeIn'>Listening.... Mute once you are done answering</span>
                                : isProcessing || isThinking ? 
                                <span className='fadeIn'>Wait for the Next Question</span>:<span className='fadeIn'>Unmute to record your answer</span> 
                            }
                        </h6>
                        
                        <div className={`mic ${isRecording ? 'rippling' : ''}`} 
                            onClick={
                                isProcessing || isThinking ? null: isRecording ? 
                                stopRecording : startRecording
                                } 
                            disabled = {isProcessing && isThinking}
                            style={{backgroundColor: `${isRecording ? "#CC2936" : 'Black'}`}}
                            >
                        {
                            isRecording ? 
                                <MicRoundedIcon style={{height:'100%', width:'100%', alignContent:'center', backgroundColor:'#CC2936'}}/> 
                                : <MicOffRoundedIcon style={{height:'100%', width:'100%', alignContent:'center'}}/>
                        }
                        </div>
                    
                    </span>
                }
                </div>
                

            </div>
            {
            // !interviewEnded && 
            <Recorder 
                isRecordingStart={isRecordingStart} 
                setRecordingStart={setRecordingStart} 
                micEnabledv2={micEnabledv2} 
                setMicEnabledv2={setMicEnabledv2}
                cameraEnabledv2={cameraEnabledv2}
                setCameraEnabledv2={setCameraEnabledv2}
                screenEnabledv2={screenEnabledv2}
                setScreenEnabledv2={setScreenEnabledv2}
                iscamRecordingv2={iscamRecordingv2}
                setIscamRecordingv2={setIscamRecordingv2}
                cameraButton={cameraButton}
                setCameraButton={setCameraButton}
                screenButton={screenButton}
                setScreenButton={setScreenButton}
                recordButton={recordButton}
                setrecordButton={setrecordButton}
                interviewEnded={interviewEnded}
                setInterviewEnded={setInterviewEnded}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                uploadProgress={uploadProgress}
                setUploadProgress={setUploadProgress}
            />}
        </div>
    );
}

export default ChatBot;
